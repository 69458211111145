import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"

const SEO = ({
  content,
  full_slug,
  title,
  description,
  og_title,
  og_description,
  og_type,
  og_url,
  og_image,
  og_image_width,
  og_image_height,
  article_published_time,
  article_modified_time,
  og_locale,
  twitter_title,
  twitter_description,
  twitter_image,
  name,
  no_description_fallback = false,
  language = null,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleSeparator
            description
            author
            siteUrlMultiLang
            color
          }
        }
      }
    `
  )
  const navContext = useContext(NavContext)

  let websiteTitle = site.siteMetadata.title
  let websiteDesc = site.siteMetadata.description
  let websiteBaseUrl = site.siteMetadata.siteUrlMultiLang

  if (
    process.env.GATSBY_IS_CORPORATE_WEBSITE === "true" &&
    navContext &&
    navContext.countryLang
  ) {
    let countryLang = navContext.countryLang

    websiteTitle = JSON.parse(site.siteMetadata.title)[countryLang]
    websiteDesc = JSON.parse(site.siteMetadata.description)[countryLang]
    websiteBaseUrl = JSON.parse(site.siteMetadata.siteUrlMultiLang)[countryLang]
  } else if (
    process.env.GATSBY_STORYBLOK_FOLDER_WHITELIST?.split(",").length > 1 &&
    language
  ) {
    websiteTitle = JSON.parse(site.siteMetadata.title)[language]
    websiteDesc = JSON.parse(site.siteMetadata.description)[language]
    websiteBaseUrl = JSON.parse(site.siteMetadata.siteUrlMultiLang)[language]
  }

  const bestMatch = (array) => {
    return array.find((e) => e !== undefined && e !== "")
  }

  // Einmal "c" vorbereiten, um unten nicht stätig prüfen zu müssen, ob content übergeben wurde
  const c =
    content && Object.keys(content).length > 0 ? content : { metadata: {} }
  const m = c.metadata ? c.metadata : {}

  const og_site_name = websiteTitle
  const twitter_creator = site.siteMetadata.twitter_creator

  const IMAGE_SIZE = {
    facebook: {
      width: "1200",
      height: "630",
    },
    twitter: {
      width: "1024",
      height: "512",
    },
  }

  const resizeImage = (image, sizes) => {
    if (/^(?=.*\bstoryblok\b).*$/.test(image)) {
      const imageService = "https://img2.storyblok.com"
      const fileName = image
        ?.replace("https://a.storyblok.com/", "")
        .replace(/https:\/\/img2\.storyblok\.com\/.*\/smart\//g, "")
      const imageSize = `${sizes.width}x${sizes.height}`
      const imageUrl = `${imageService}/${imageSize}/${fileName}`
      return imageUrl
    } else if (/^(?=.*\bcontentserv\b).*$/.test(image)) {
      return `https://res.cloudinary.com/${
        process.env.CLOUDINARY_NAME
      }/image/fetch/c_pad,b_rgb:ffffff,h_${sizes.height},w_${
        sizes.width
      }/f_auto/${encodeURIComponent(image)}`
    } else if (/^(?=.*\bshopify\b).*$/.test(image)) {
      return `https://res.cloudinary.com/${
        process.env.CLOUDINARY_NAME
      }/image/fetch/c_pad,b_rgb:ffffff,h_${sizes.height},w_${
        sizes.width
      }/f_auto/${encodeURIComponent(image)}`
    } else {
      return image
    }
  }

  let data = []

  // SEO Variablen (einige sind optional)
  data.push({
    name: `title`,
    content: `${bestMatch([
      m.title,
      title,
      c.headline,
      c.title,
      m.og_title,
      og_title,
      name,
    ])}${site.siteMetadata.titleSeparator}${websiteTitle}`,
  })
  data.push({
    name: `description`,
    content:
      no_description_fallback === false
        ? bestMatch([
            m.description,
            description,
            c.subheadline,
            c.teaser,
            m.og_decription,
            og_description,
            websiteDesc,
          ])
        : "",
  })
  data.push({
    property: `msapplication-TileColor`,
    content: site.siteMetadata.color,
  })
  data.push({
    property: `og:site_name`,
    content: og_site_name,
  })
  data.push({
    property: `og:title`,
    content: bestMatch([
      m.og_title,
      og_title,
      m.title,
      c.headline,
      c.title,
      title,
    ]),
  })
  data.push({
    property: `og:description`,
    content:
      no_description_fallback === false
        ? bestMatch([
            m.og_description,
            og_description,
            m.description,
            description,
            c.subheadline,
            c.teaser,
            websiteDesc,
          ])
        : "",
  })
  data.push({
    property: `og:type`,
    content: og_type ? og_type : "website",
  })
  data.push({
    property: `og:url`,
    content: bestMatch([og_url, full_slug]),
  })
  data.push({
    property: `og:image`,
    content: resizeImage(
      bestMatch([og_image, m.og_image, c.hero_image]),
      IMAGE_SIZE.facebook
    ),
  })
  data.push({
    property: `og:image:width`,
    content: og_image_width,
  })
  data.push({
    property: `og:image:height`,
    content: og_image_height,
  })
  data.push({
    property: `article:published_time`,
    content: article_published_time,
  })
  data.push({
    property: `article:modified_time`,
    content: article_modified_time,
  })
  data.push({
    property: `og:locale`,
    content: bestMatch([og_locale, c.locale]),
  })
  data.push({
    name: `twitter:card`,
    content: `summary_large_image`,
  })
  data.push({
    name: `twitter:title`,
    content: bestMatch([
      m.twitter_title,
      m.og_title,
      twitter_title,
      og_title,
      m.title,
      c.headline,
      c.title,
      title,
    ]),
  })
  data.push({
    name: `twitter:description`,
    content:
      no_description_fallback === false
        ? bestMatch([
            m.twitter_description,
            m.og_description,
            twitter_description,
            og_description,
            m.description,
            description,
            c.subheadline,
            c.teaser,
            websiteDesc,
          ])
        : "",
  })
  data.push({
    name: `twitter:site`,
    content: twitter_creator,
  })
  data.push({
    name: `twitter:creator`,
    content: twitter_creator,
  })
  data.push({
    name: `twitter:image`,
    content: resizeImage(
      bestMatch([
        twitter_image,
        m.twitter_image,
        m.og_image,
        og_image,
        c.hero_image,
      ]),
      IMAGE_SIZE.twitter
    ),
  })

  data.push({
    name: `viewport`,
    content:
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0",
  })

  data = data.filter((x) => x.content && x.content !== undefined)

  return (
    <Helmet
      title={`${bestMatch([m.title, title, c.title, name])} ${
        content && content.component === "start_page"
          ? ""
          : `${site.siteMetadata.titleSeparator}${websiteTitle}`
      }`}
      meta={data.concat()}
    />
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

SEO.defaultProps = {
  og_image_width: "1200",
  og_image_height: "630",
  og_locale: "de",
}

export default SEO
