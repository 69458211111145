const switchedObjectNameOrLabel = (obj) => {
  const useMarketingLabel = process.env.GATSBY_USE_MARKETING_LABEL === "true"

  if (useMarketingLabel && obj.marketingLabel !== "") {
    return obj.marketingLabel
  } else {
    return obj.name
  }
}

const deconstructOrbitVuEmbedCode = (embed) => {
  const regex = /<script[^>]*?src="([^"]+)"[^>]*><\/script>\s*([\s\S]+)/
  // Match ausführen
  const match = embed.match(regex)
  if (match) {
    const scriptSrc = match[1] // src-Attribut des Script-Tags
    let markup = match[2] // Restliches Markup

    // Entferne unnötige Leerzeichen aus dem Markup
    markup = markup.replace(/\s+/g, " ").trim()

    // Ersetze inline width und height durch 100%
    markup = markup.replace(/(width|height):\s*[\d.]+px/g, "$1: 100%")

    // Ergebnisobjekt
    return {
      scriptSrc: scriptSrc,
      markup: markup,
    }
  } else {
    return {
      scriptSrc: null,
      markup: embed,
    }
  }
}

module.exports = {
  switchedObjectNameOrLabel: switchedObjectNameOrLabel,
  deconstructOrbitVuEmbedCode: deconstructOrbitVuEmbedCode,
}
