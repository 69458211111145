import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import { switchedObjectNameOrLabel } from "gatsby-theme-rothenberger/src/utilities/product-data-utils"
import ProductVariantCard from "gatsby-theme-rothenberger/src/components/molecules/ProductVariantCard"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"

const ProductGroupTemplate = ({ data }) => {
  const entry = data.entry
  const productGroups = data.productGroups.edges
  const line = data.line
  const area = data.area
  const products = data.products.edges

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
    { link: line.fullSlug, link_text: line.name },
  ]

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        description={`${entry.name} | ${line.name} | ${area.name}`}
        language={entry.language}
      />
      <HeroBlock block={{ headline: entry?.name }} breadcrumbs={breadcrumbs} />
      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-ro-gray-100">
                {productGroups.map((group) => {
                  return (
                    <div className="block" key={group.node.fullSlug}>
                      <Link
                        className={`inline-block py-2 text-sm px-grid hover:text-ro-red${
                          group.node.ID === entry.ID
                            ? " font-bold text-ro-red"
                            : ""
                        }`}
                        link={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product, index) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={index}
                  >
                    <ProductVariantCard
                      image={
                        product?.node?.properties?.mainImage !== "undefined"
                          ? product.node.properties.mainImage
                          : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                      }
                      title={switchedObjectNameOrLabel(product.node)}
                      text={product.node.properties.groupName}
                      link={product.node.fullSlug}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ProductGroupQuery(
    $id: String
    $lineId: String
    $areaId: String
    $locale: String
  ) {
    entry: contentServProductGroup(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      language
      externalKey
      ID
      rootPage {
        name
        fullSlug
      }
    }
    productGroups: allContentServProductGroup(
      filter: { lineId: { eq: $lineId }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          name
          language
          fullSlug
          externalKey
          ID
        }
      }
    }
    line: contentServProductLine(
      lineId: { eq: $lineId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    products: allContentServProduct(
      filter: { parentId: { eq: $id }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          marketingLabel
          groupId
          ID
          treeSorting
          fullSlug
          parentId
          externalKey
          properties {
            mainImage
            groupName
          }
        }
      }
    }
  }
`

export default ProductGroupTemplate
